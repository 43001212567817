/* CSS Document
author: Chris Sparshott
use of conditional comments have been used throughout site, therefore several styles have been filtered into appropriate IE6/IE7 CSS documents - notes have been made next to relevant css rules*/
/* ------------------------------------------------------------------
Sets default margin and padding
-------------------------------------------------------------------*/

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset {
  margin: 0;
  padding: 0;
  border: none;

  /*outline:none;*/
}

input {
  &[type="text"], &[type="password"] {
    margin: 0;
    padding: 0;
    border: none;

    /*outline:none;*/
  }
}

textarea, p, blockquote, table, th, td {
  margin: 0;
  padding: 0;
  border: none;

  /*outline:none;*/
}

/* ------------------------------------------------------------------
 Sets default font-size for headers
 -------------------------------------------------------------------*/

h1, h2, h3, h4, h5 {
  font-size: 100%;
}

/* ------------------------------------------------------------------
Sets font-style and font-weight to normal
-------------------------------------------------------------------*/

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}

/* ------------------------------------------------------------------
Sets font family for form elements
-------------------------------------------------------------------*/

input {
  &[type="text"], &[type="password"], &[type="email"] {
    padding: 0;
    margin: 0;
  }
}

select {
  padding: 0;
  margin: 0;
}

textarea {
  padding: 0;
  margin: 0;
  overflow: auto;
  resize: none;
}

button {
  /* width important and overflow:visible needed for IE7 weird padding */
  width: auto !important;
  overflow: visible;
  cursor: pointer;
}

input {
  &[type="button"], &[type="image"], &[type="submit"], &[type="reset"] {
    /* width important and overflow:visible needed for IE7 weird padding */
    width: auto !important;
    overflow: visible;
    cursor: pointer;
  }
}

/* ------------------------------------------------------------------
Removes border from fieldset and image
-------------------------------------------------------------------*/

fieldset, img {
  border: 0;
}

/* ------------------------------------------------------------------
Link styles
-------------------------------------------------------------------*/

a {
  outline: none;
  cursor: pointer;

  &:link, &:visited {
    outline: none;
    cursor: pointer;
  }
}

/* ------------------------------------------------------------------
=table styles
-------------------------------------------------------------------*/

table {
  border-collapse: collapse;
}
